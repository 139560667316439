import request from './api.js'
// 获取地区
export function getarea(params) {
    return request({
        url: 'api/config/area',
        method: 'get',
        params,
    })
}
// 获取用户地址
export function getuserarea(params) {
    return request({
        url: 'api/member_address/index',
        method: 'get',
        params,
    })
}
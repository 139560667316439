<template>
  <van-form @submit="onSubmit" class="myadd">
    <van-field
      v-model="form.full_name"
      name="姓名"
      label="姓名"
      placeholder="姓名"
      :rules="[{ required: true, message: '请填写姓名' }]"
    />
    <van-field
      v-model="form.mobile"
      name="tel"
      label="电话"
      placeholder="电话"
      :rules="[{ required: true, message: '请填写电话' }]"
    />
    <van-field
      readonly
      clickable
      label="城市"
      :value="value"
      placeholder="选择城市"
      @click="showPicker = true"
    />
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        title="请选择地址"
        :columns="columns"
        @confirm="changaddress"
        @cancel="showPicker = false"
      />
    </van-popup>

    <van-field v-model="form.address" label="详细地址" placeholder="详细地址" />
    <van-field name="switch" label="是否默认地址">
      <template #input>
        <van-switch v-model="is_default" size="20" />
      </template>
    </van-field>
    <div style="margin: 16px">
      <van-button round block type="info" native-type="submit"> 提交 </van-button>
    </div>
  </van-form>

  <!-- <van-field
        readonly
        clickable
        label="城市"
        :value="value"
        placeholder="选择城市"
        @click="showPicker = true"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          title="请选择地址"
          :columns="columns"
          @confirm="changaddress"
          @cancel="showPicker = false"
        />
      </van-popup>

      <van-field
        v-model="form.address"
        label="详细地址"
        placeholder="详细地址"
       
      /> -->
</template>
<script>
import { Toast } from "vant";
import { getarea } from "@/api/components";
import { add_address } from "@/api/user";

export default {
  data() {
    return {
      searchResult: [],
      showPicker: false,
      columns: [],
      value: "",
      is_default: "",
      form: {
        full_name: "",
        mobile: "",
        province: "95",
        city: "96",
        area: "98",
        address: "",
        is_default: this.is_default ? 0 : 1,
        id: "",
      },
    };
  },
  mounted() {
    this.getarealist();
  },
  methods: {
    changaddress(item, item2) {
      console.log(item2);
      this.form.province = this.columns[item2[0]].id;
      this.form.city = this.columns[item2[0]].children[item2[1]].id;
      this.form.area = this.columns[item2[0]].children[item2[1]].children[item2[2]].id;
      this.value = item.join("");
      this.showPicker = false;
    },
    async onSubmit() {
      const { msg, code } = await add_address(this.form);
      console.log(msg,code)
    },
    // 获取地区
    async getarealist() {
      const res = await getarea();
      let addlist = JSON.stringify(res.data.data)
        .replace(/title/g, "text")
        .replace(/child/g, "children");

      this.columns = JSON.parse(addlist);

      this.columns.forEach((ele) => {
        if (ele.id == this.form.province) {
          this.value += ele.text;
          ele.children.forEach((item) => {
            if (item.id == this.form.city) {
              this.value += item.text;
              item.children.forEach((item2) => {
                if (item2.id == this.form.area) {
                  this.value += item2.text;
                }
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style>
.myadd .van-switch__node {
  font-size: 20px;
}
.myadd .van-switch--on .van-switch__node {
  transform: translateX(22px);
}
</style>
